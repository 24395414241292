import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SeasonsList } from "./Metadata/Constants.js";
function SvFilterTemporadas({ filter, setFilter, title }) {
  return (
    <>
      <Row className="mt-2">
        <Col>
          <h2 className="SvTitulo">{title}</h2>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12} md={9}>
          <Form.Control
            id="idBuscadorTitulo"
            type="text"
            placeholder="Quina obra estas buscant?"
            className="w-100 float-end form-control-lg"
            defaultValue={filter.title}
            onChange={(event) => {
              setFilter({ season: filter.season, title: event.target.value });
            }}
          />
        </Col>
        <Col xs={12} md={3}>
          <div className="w-100 float-end">
            <select
              className="form-select form-select-lg"
              name="season"
              value={filter.season}
              onChange={(event) =>
                setFilter({ season: event.target.value, title: filter.title })
              }
            >
              <option value="">Totes les temporades</option>
              {SeasonsList.map((element, index) => {
                return (
                  <option key={index} value={element}>
                    {element}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SvFilterTemporadas;
