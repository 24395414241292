import { Container, Navbar, Nav } from "react-bootstrap";
import logoSV from ".././assets/logoSV.png";
import { Link, useNavigate } from "react-router-dom";

function SVHeader({ usuario }) {
  const navigate = useNavigate();

  const handleLinkClick = (ruta) => {
    navigate.push(ruta);
  };

  return (
    <Navbar className="SVNavbar shadow mb-2" variant="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logoSV}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Santviflix
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {usuario ? (
          <>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {usuario.isSubscriptionActive ? (
                  <>
                    <Nav.Link
                      as={Link}
                      to="obres"
                      onClick={() => handleLinkClick("/obres")}
                    >
                      Obres de teatre
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="tallerDeTeatre"
                      onClick={() => handleLinkClick("/tallerDeTeatre")}
                    >
                      Taller de Teatre
                    </Nav.Link>
                  </>
                ) : (
                  ""
                )}
                {usuario.isAdmin ? (
                  <>
                    <Nav.Link
                      as={Link}
                      to="config"
                      onClick={() => handleLinkClick("/config")}
                    >
                      Configuració
                    </Nav.Link>
                    <Nav.Link as={Link} to="users">
                      Usuaris
                    </Nav.Link>
                  </>
                ) : (
                  ""
                )}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Signed in as: <Link to="perfil">{usuario.email}</Link>
              </Navbar.Text>
            </Navbar.Collapse>
          </>
        ) : (
          ""
        )}
      </Container>
    </Navbar>
  );
}

export default SVHeader;
