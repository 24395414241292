import "./Styles/SVComponents.css";
import { FaCloudDownloadAlt } from "react-icons/fa";

function SVButtonDownload(props) {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = props.url;
    link.setAttribute("download", props.name || "video");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <div className="boton-reproduccion" onClick={() => handleDownload()}>
      <FaCloudDownloadAlt className="icono-download" />
    </div>
  );
}

export default SVButtonDownload;
