import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

const SVLoginForm = ({ toggleSignup, setUsuario }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordReset = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Contraseña restaurada. Revisa la bústia del correu.");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const db = getFirestore();
        getDoc(doc(db, "Users", userCredential.user.uid)).then((res) => {
          setUsuario(res.data());
        });
      })
      .catch((error) => {
        alert(error.code);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Correu electrònic</Form.Label>
        <Form.Control
          type="email"
          placeholder="Introdueix el teu correu electrònic"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
      </Form.Group>

      <Form.Group className="mt-2 " controlId="formBasicPassword">
        <Form.Label>Contrasenya</Form.Label>
        <Form.Control
          type="password"
          placeholder="Introdueix la teva contrasenya"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />
      </Form.Group>

      <Button
        className="mt-3 boton-redCorporative"
        variant="primary"
        type="submit"
      >
        Accedir
      </Button>

      <Button className="mt-3" variant="link" onClick={toggleSignup}>
        Crear un compte nou
      </Button>

      <Button
        className="mt-3"
        variant="link"
        onClick={() => handlePasswordReset()}
      >
        Restaurar contraseña
      </Button>
    </Form>
  );
};

export default SVLoginForm;
