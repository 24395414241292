// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDAk_k6iWPq_uV1a-RQFQ4rnQ0MSikHKe4",
  authDomain: "santviflix-160e4.firebaseapp.com",
  projectId: "santviflix-160e4",
  storageBucket: "santviflix-160e4.appspot.com",
  messagingSenderId: "751517568116",
  appId: "1:751517568116:web:9fadd681ca4ff1a8445521",
  measurementId: "G-3TQQ9DMBC2",
  storageBucket: "gs://santviflix-160e4.appspot.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);
