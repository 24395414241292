import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import SVLoginForm from "../components/Usuarios/SVLoginForm";
import SVSignupForm from "../components/Usuarios/SVSignupForm";
import SVButtonTermsConditions from "../components/Usuarios/SVButtonTermsConditions";

const SVLoginPage = ({ setUsuario }) => {
  const [showLogin, setShowLogin] = useState(true);

  const toggleLogin = () => setShowLogin(!showLogin);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header className="text-center fs-5 fw-bold">
              {showLogin ? "Iniciar sessió" : "Crear nova conta d'usuari"}
            </Card.Header>
            <Card.Body>
              {showLogin ? (
                <SVLoginForm
                  toggleSignup={toggleLogin}
                  setUsuario={setUsuario}
                />
              ) : (
                <SVSignupForm
                  toggleLogin={toggleLogin}
                  setUsuario={setUsuario}
                />
              )}
            </Card.Body>
          </Card>
          <SVButtonTermsConditions />
        </Col>
      </Row>
    </Container>
  );
};

export default SVLoginPage;
