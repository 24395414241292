import React, { useState, useContext } from "react";

const filterContext = React.createContext();

export function useFilterContext() {
  return useContext(filterContext);
}

export function FilterProvider({ children }) {
  const [filter, setFilter] = useState(null);
  return (
    <filterContext.Provider value={{ filter, setFilter }}>
      {children}
    </filterContext.Provider>
  );
}
