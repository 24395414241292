import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore/lite";
import SVInfoSub from "../components/Usuarios/SVInfoSub";
import { useNavigate } from "react-router-dom";

function SVCarrouselPage({ usuario }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [shows, setShows] = useState();

  useEffect(() => {
    const db = getFirestore();
    const queryShows = query(
      collection(db, "Shows"),
      where("isActivated", "==", true),
      where("type", "in", ["T", "M", "I"]),
      orderBy("premiereDate", "desc"),
      limit(4)
    );
    getDocs(queryShows).then((res) => {
      const wShows = [];
      res.forEach((doc) => {
        wShows.push({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          shortDescription: doc.data().shortDescription,
          type: doc.data().type,
          premiereDate: doc.data().premiereDate,
          season: doc.data().season,
          directors: doc.data().directors,
          descriptionLink1: doc.data().descriptionLink1,
          urlLink1: doc.data().urlLink1,
          descriptionLink2: doc.data().descriptionLink2,
          urlLink2: doc.data().urlLink2,
          refPosterImage: doc.data().refPosterImage,
        });
      });
      setShows(wShows);
    });
  }, []);

  const navigate = useNavigate();

  const handleShowClick = (id) => {
    navigate("/obre/" + id);
  };

  return (
    <>
      {usuario.isSubscriptionActive ? (
        <div className="mt-2">
          <h2 className="SvTitulo">Últimes estrenes</h2>
          <hr />
          <Container className="d-flex flex-row justify-content-center alig-items-center">
            {shows ? (
              <Carousel
                className={
                  window.innerWidth <= 768
                    ? "mt-2 w-100 shadow"
                    : "mt-2 w-50 shadow"
                }
                variant="dark"
                interval="1500"
                activeIndex={index}
                onSelect={handleSelect}
              >
                {shows.map((show, index) => (
                  <Carousel.Item key={index}>
                    <Image
                      className="d-block w-100 efectoZoom"
                      src={show.refPosterImage}
                      alt="El crèdit"
                      rounded="true"
                      onClick={() => handleShowClick(show.id)}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              ""
            )}
          </Container>
        </div>
      ) : (
        <SVInfoSub />
      )}
    </>
  );
}

export default SVCarrouselPage;
