import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getFirestore,
  doc,
  setDoc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../App.css";
import { SeasonsList } from "./Constants.js";

class SVLinkFormularioObra extends Component {
  constructor(props) {
    super(props);
    if (this.props.dataShow.id != null) {
      this.state = {
        type: this.props.dataShow.type,
        season: this.props.dataShow.season,
        eliminarShow: false,
        file: null,
        refPosterImage: this.props.dataShow.refPosterImage,
        isActivated: this.props.dataShow.isActivated ? true : false,
        idTab: "general",
        links: [],
      };
    } else {
      this.state = {
        type: "T",
        season: this.props.season,
        eliminarShow: false,
        file: null,
        refPosterImage: this.props.dataShow.refPosterImage,
        isActivated: false,
        idTab: "general",
        links: [],
      };
    }
  }

  modalEliminarClose = () => this.setState({ eliminarShow: false });
  modalEliminarShow = () => this.setState({ eliminarShow: true });

  handleSubmit = async (e) => {
    e.preventDefault();

    this.saveObraFirebase();
  };

  saveObraFirebase() {
    const [day, month, year] = this.refs.premiereDate.value.split("/");
    const premierDateFormatted = new Date([month, day, year].join("/"));
    console.log(this.refs);
    const show = {
      title: this.refs.title.value,
      description: this.refs.description.value,
      shortDescription: this.refs.shortDescription.value,
      directors: this.refs.directors.value,
      premiereDate: Timestamp.fromDate(new Date(premierDateFormatted)),
      season: this.refs.season.value,
      type: this.refs.form.type.value,
      descriptionLink1: this.refs.descriptionLink1.value,
      urlLink1: this.refs.urlLink1.value,
      urlDownload1: this.refs.urlDownload1.value,
      descriptionLink2: this.refs.descriptionLink2.value,
      urlLink2: this.refs.urlLink2.value,
      urlDownload2: this.refs.urlDownload2.value,
      descriptionLink3: this.refs.descriptionLink3.value,
      urlLink3: this.refs.urlLink3.value,
      urlDownload3: this.refs.urlDownload3.value,
      descriptionLink4: this.refs.descriptionLink4.value,
      urlLink4: this.refs.urlLink4.value,
      urlDownload4: this.refs.urlDownload4.value,
      descriptionLink5: this.refs.descriptionLink5.value,
      urlLink5: this.refs.urlLink5.value,
      urlDownload5: this.refs.urlDownload5.value,
      descriptionLink6: this.refs.descriptionLink6.value,
      urlLink6: this.refs.urlLink6.value,
      urlDownload6: this.refs.urlDownload6.value,
      refPosterImage: this.state.refPosterImage,
      isActivated: this.state.isActivated,
    };
    const db = getFirestore();
    setDoc(doc(db, "Shows", this.refs.id.value), show).then((res) => {
      this.props.close();
    });
  }

  handleDelete = () => {
    const db = getFirestore();
    deleteDoc(doc(db, "Shows", this.refs.id.value)).then((res) => {
      this.props.close();
    });
  };

  handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    if (target.type === "checkbox" && !target.checked) {
      value = "";
    }
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleUploadFile = (event) => {
    const file = event.target.files[0];
    const storage = getStorage();
    const spaceRef = ref(
      storage,
      "posterImages/" + this.refs.season.value + "/" + this.refs.id.value
    );
    uploadBytes(spaceRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        this.setState({ refPosterImage: downloadURL });
      });
    });
  };

  render() {
    const dataShow = this.props.dataShow;
    return (
      <>
        <div className="mb-3">
          <ul class="nav nav-tabs border-bottom mb-3">
            <li class="nav-item">
              <button
                class={`nav-link ${this.state.idTab === "general" && "active"}`}
                onClick={() => this.setState({ idTab: "general" })}
              >
                Informació General
              </button>
            </li>
            <li class="nav-item">
              <button
                class={`nav-link ${this.state.idTab === "links" && "active"}`}
                onClick={() => this.setState({ idTab: "links" })}
              >
                Links
              </button>
            </li>
            <li class="nav-item">
              <button
                class={`nav-link ${this.state.idTab === "image" && "active"}`}
                onClick={() => this.setState({ idTab: "image" })}
              >
                Foto
              </button>
            </li>
          </ul>
          <form onSubmit={this.handleSubmit} ref="form">
            <div
              style={{
                display: this.state.idTab === "general" ? "block" : "none",
              }}
            >
              <div class="d-flex justify-content-between">
                <div class="form-floating">
                  <input
                    className="form-control"
                    type="text"
                    ref="id"
                    placeholder="Id"
                    value={dataShow.id}
                  ></input>
                  <label for="id">Id</label>
                </div>
                <div class="form-check form-check-reverse form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    ref="isActivated"
                    id="checkActiva"
                    onChange={() =>
                      this.setState({
                        isActivated: !this.state.isActivated,
                      })
                    }
                    checked={this.state.isActivated}
                  />
                  <label class="form-check-label" for="checkActiva">
                    Activa
                  </label>
                </div>
              </div>
              <div className="mt-3 form-floating">
                <input
                  className="form-control w-50"
                  type="text"
                  ref="title"
                  placeholder="Títul"
                  defaultValue={dataShow.title}
                ></input>
                <label for="title">Titul</label>
              </div>
              <div className="mt-3">
                <textarea
                  className="form-control textarea-size-short"
                  ref="shortDescription"
                  placeholder="Descripció curta"
                  defaultValue={dataShow.shortDescription}
                ></textarea>
              </div>
              <div className="mt-3">
                <textarea
                  className="form-control textarea-size-large"
                  ref="description"
                  rows="3"
                  placeholder="Descripció llarga"
                  defaultValue={dataShow.description}
                ></textarea>
              </div>
              <div className="mt-3 form-floating">
                <input
                  className="form-control w-50"
                  type="text"
                  ref="directors"
                  placeholder="Directors"
                  defaultValue={dataShow.directors}
                ></input>
                <label for="directors">Directors</label>
              </div>
              <div className="pt-3 pb-1 ">
                <label>Categoria: </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type"
                  id="typeText"
                  value="T"
                  checked={this.state.type === "T"}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" for="typeText">
                  Text
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type"
                  id="typeMusical"
                  value="M"
                  checked={this.state.type === "M"}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" for="typeMusical">
                  Musical
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type"
                  id="typeInfantil"
                  value="I"
                  checked={this.state.type === "I"}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" for="typeInfantil">
                  Infantil
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type"
                  id="typeTaller"
                  value="X"
                  checked={this.state.type === "X"}
                  onChange={this.handleChange}
                />
                <label className="form-check-label" for="typeTaller">
                  Taller de Teatre
                </label>
              </div>
              <div className="mt-3 form-floating">
                <input
                  required
                  className="form-control w-25"
                  type="text"
                  ref="premiereDate"
                  placeholder="dd/mm/yyyy"
                  defaultValue={
                    dataShow.premiereDate
                      ? dataShow.premiereDate.toDate().toLocaleDateString()
                      : null
                  }
                ></input>
                <label for="premiereDate">Data d'estrena</label>
              </div>
              <div className="mt-3 form-floating">
                <select
                  ref="season"
                  className="form-select w-25"
                  name="season"
                  value={this.state.season}
                  onChange={this.handleChange}
                >
                  {SeasonsList.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
                <label for="season">Temporada</label>
              </div>
            </div>
            <div
              style={{
                display: this.state.idTab === "links" ? "block" : "none",
              }}
            >
              <div className="pt-3 pb-2 ">
                <label>Links: </label>
              </div>
              {[...Array(6)].map((_, index) => (
                <>
                  <div
                    key={index}
                    className={`form-floating ${index > 0 && "mt-3"}`}
                  >
                    <input
                      className="form-control w-25"
                      type="text"
                      ref={`descriptionLink${index + 1}`}
                      placeholder="Descripció"
                      defaultValue={dataShow[`descriptionLink${index + 1}`]}
                    ></input>
                    <label for={`descriptionLink${index + 1}`}>
                      Descripció link
                    </label>
                  </div>
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      ref={`urlLink${index + 1}`}
                      placeholder="Url"
                      defaultValue={dataShow[`urlLink${index + 1}`]}
                    ></input>
                    <label for={`urlLink${index + 1}`}>Url link</label>
                  </div>
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      ref={`urlDownload${index + 1}`}
                      placeholder="Url"
                      defaultValue={dataShow[`urlDownload${index + 1}`]}
                    ></input>
                    <label for={`urlDownload${index + 1}`}>Url descarga</label>
                  </div>
                </>
              ))}
            </div>
            <div
              style={{
                display: this.state.idTab === "image" ? "block" : "none",
              }}
            >
              <div className="pt-3 pb-3">
                <label for="posterImage">Foto:</label>
                <img
                  src={this.state.refPosterImage}
                  class="rounded mx-auto d-block w-25 pb-3"
                  alt="Imagen"
                ></img>
                <input
                  className="form-control"
                  type="file"
                  ref="posterImage"
                  onChange={(e) => this.handleUploadFile(e)}
                ></input>
              </div>
            </div>
            <div className="pt-3">
              <input
                className="btn btn-primary me-2 boton-redCorporative"
                type="submit"
                value={dataShow.id ? "Modificar" : "Crear"}
              />
              <button
                type="button"
                className="btn btn-primary me-2 boton-redCorporative"
                onClick={this.props.close}
              >
                Cancelar
              </button>
              {dataShow.id ? (
                <>
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={this.modalEliminarShow}
                  >
                    Eliminar
                  </button>
                  <Modal
                    show={this.state.eliminarShow}
                    onHide={this.modalEliminarClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.props.dataShow.title} serà eliminada
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={this.modalEliminarClose}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="primary"
                        className="boton-redCorporative"
                        onClick={() => this.handleDelete()}
                      >
                        Eliminar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}
SVLinkFormularioObra.propTypes = {
  dataShow: PropTypes.object,
  close: PropTypes.func,
};
export default SVLinkFormularioObra;
