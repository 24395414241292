import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore/lite";
import SVButtonTermsConditions from "./Usuarios/SVButtonTermsConditions";

function SVPerfil({ usuario, setUsuario }) {
  const [subscriptionActive, setSubscriptionActive] = useState(
    usuario.isSubscriptionActive
  );
  const [activar, setActivar] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState(null);

  const handleDoSubscription = (e) => {
    e.preventDefault();
    const db = getFirestore();
    getDoc(doc(db, "SubscriptionCodes", subscriptionCode)).then((res) => {
      if (res.data()) {
        if (!res.data().isActived) {
          setSubscriptionActive(true);
          const codeRef = doc(db, "SubscriptionCodes", subscriptionCode);
          updateDoc(codeRef, { isActived: true, uid: usuario.id });
          const userRef = doc(db, "Users", usuario.id);
          updateDoc(userRef, {
            isSubscriptionActive: true,
            subscriptionCode: subscriptionCode,
            activeDate: new Date(),
          });
        } else {
          alert("Codi ja utilitzat");
        }
      } else {
        alert("Codi incorrecta");
      }
    });
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setUsuario(null);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <>
      <Container className="mt-2">
        <Row className="mt-2">
          <h2 className="SvTitulo">Perfil del usuari</h2>
          <hr />
        </Row>
        <Row>
          <Col xs={8}>
            <Table size="sm">
              <tbody>
                <tr>
                  <td className="fw-bold">Nom</td>
                  <td>{usuario.firstName + " " + usuario.lastName} </td>
                </tr>
                <tr>
                  <td className="fw-bold">Email</td>
                  <td>{usuario.email}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Subscripció obres de teatre</td>

                  {subscriptionActive ? (
                    <td className="align-middle text-success">Activada</td>
                  ) : (
                    <td className="align-middle">
                      {activar ? (
                        <Form className="d-flex flex-row ">
                          <Form.Group controlId="formActivarTaller">
                            <Form.Control
                              type="text"
                              placeholder="Codi de subscripció"
                              value={subscriptionCode}
                              onChange={(e) =>
                                setSubscriptionCode(e.target.value)
                              }
                            />
                          </Form.Group>

                          <Button
                            className="ms-2"
                            variant="primary"
                            type="submit"
                            onClick={handleDoSubscription}
                          >
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="align-middle text-danger me-3">
                            Desactivada
                          </div>
                          <Button
                            onClick={() => {
                              setActivar(true);
                            }}
                          >
                            <i className="bi bi-check-square me-2"></i>
                            Subscriure's
                          </Button>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
            <Button onClick={handleSignOut} className="mt-4" variant="link">
              SignOut
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <SVButtonTermsConditions />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SVPerfil;
