import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function SVButtonTermsConditions() {
  const navigate = useNavigate();

  return (
    <Button variant="link" onClick={() => navigate("/terms")}>
      Termes i condicions
    </Button>
  );
}

export default SVButtonTermsConditions;
