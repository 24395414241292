function SVTermsCOnditions() {
  return (
    <div className="container">
      <h4>1. - IDENTIFICACIÓ DEL TITULAR DEL DOMINI</h4>
      En compliment del deure d'informació recollit a l'article 10 de la Llei
      34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i del
      Comerç Electrònic, a continuació es publiquen les següents dades: El
      titular d'aquest lloc web és el Centre Parroquial Sant Vicenç, amb
      domicili al carrer Montllor i Pujal 103, 08208 Sabadell, amb número de NIF
      R0800649F.
      <div>Telèfon de contacte:937179401</div>
      <div> Correu electrònic de contacte: correu@teatrecpsv.com</div>
      <h4 className="mt-2">2. - CONDICIONS D'ÚS DEL WEB</h4>
      S'entendrà com a usuari a la persona que accedeix i faci servir aquest
      lloc web. L'usuari pel sol fet de ser-ho accepta aquestes condicions d'ús.
      <br />
      L'usuari assumeix la responsabilitat de l'ús que pugui fer d'aquest web.
      Aquesta responsabilitat s'estén al registre que fos necessari per accedir
      a determinats serveis o continguts. L'usuari serà responsable d'aportar
      informació veraç i lícita en el procés de registre.
      <br />
      <br />
      L'USUARI es compromet a fer un ús adequat dels continguts i serveis que el
      Centre Parroquial Sant Vicenç ofereix a través d'aquest web i amb caràcter
      enunciatiu però no limitador, a no fer-los servir per a:
      <br />
      <br />
      1. Provocar danys en els sistemes físics i lògics del Centre Parroquial
      Sant Vicenç, dels seus proveïdors o de terceres persones, introduir o
      difondre a la xarxa virus informàtics o qualsevol altres sistemes físics o
      lògics que siguin susceptibles de provocar els danys anteriorment
      esmentats.
      <br />
      <br />
      2. Intentar accedir i, si s'escau, fer servir els comptes de correu
      electrònic d'altres usuaris i modificar o manipular els seus missatges.{" "}
      <br />
      <br />
      Queda prohibida la publicació en els nostres espais web de continguts que
      atemptin contra els principis enumerats a continuació, reservant-se el
      titular del domini l'exercici de quantes accions siguin procedents en dret
      contra els responsables de la seva publicació: <br />
      <br />
      1. La salvaguarda de l'ordre públic, la investigació penal, seguretat
      pública i defensa nacional, <br />
      <br />
      2. La protecció de la salut pública o de les persones físiques que tinguin
      la condició de consumidors o usuaris, <br />
      <br />
      3. El respecte a la dignitat de la persona i al principi de no
      discriminació per motius de raça, sexe, opinió, nacionalitat, discapacitat
      o qualsevol altra circumstància personal o social, <br />
      <br />
      4. La protecció de la joventut i de la infància, <br />
      <br />
      5. El respecte a l'autonomia de la voluntat de l'afectat, <br />
      <br />
      6. El respecte a la propietat intel•lectual del publicat, <br />
      <br />
      7. En general, el respecte a la legalitat vigent
      <br />
      <br /> El Centre Parroquial Sant Vicenç es reserva el dret de retirar tots
      aquells comentaris i aportacions que vulnerin el respecte a la dignitat de
      la persona, que siguin discriminatoris, xenòfobs, racistes, pornogràfics,
      que atemptin contra la joventut o la infància, l'ordre o la seguretat
      pública o que, al seu judici, no fossin adequats per a la seva publicació.
      En qualsevol cas, el titular del domini no serà responsable de les
      opinions abocades pels usuaris a través dels fòrums, xats, o altres eines
      de participació que es puguin incorporar a aquesta web.
      <h4 className="mt-3">3.-PROPIETAT INTEL•LECTUAL I INDUSTRIAL</h4>
      El Centre Parroquial Sant Vicenç és titular de tots els drets de propietat
      intel•lectual i industrial de les seves pàgines web, com també ho és de
      tot el seu contingut, per la qual cosa tota reproducció, distribució i
      comunicació pública total o parcial del seu contingut queda expressament
      prohibida sense l'autorització expressa del titular.
      <h4 className="mt-3">4. - RESPONSABILITAT</h4> El titular del domini no
      serà responsable dels danys i perjudicis que es puguin derivar
      d'interferències o avaries telefòniques, desconnexions en el sistema
      electrònic, presència de virus informàtics, programes maliciosos o
      qualsevol altre factor aliè al seu control. Tampoc serà responsable dels
      continguts o sistemes de tercers connectats amb el seu domini.{" "}
      <h4 className="mt-3">5. - PRIVACITAT </h4> 5.1. - DRET D'INFORMACIÓ <br />
      En virtut del que disposa l'article 5 de la Llei Orgànica 15/1999 de 13 de
      desembre, de Protecció de Dades de Caràcter Personal (en endavant LOPD) i
      el 13 del Reglament Europeu de Protecció de dades, us informem que: el
      Centre Parroquial Sant Vicenç, amb domicili al carrer Montllor i Pujal
      103, 08208 Sabadell, és el responsable del tractament de dades de caràcter
      personal. Les dades que se'ns facilitin a través del formulari que es
      troba al web i les que ens enviïn a la nostra adreça de correu electrònic,
      seran incorporades, tal com ens les cedeixin, a un fitxer i seran
      utilitzades per gestionar l'enviament d'informació sobre les nostres
      activitats a través del correu electrònic o adreça postal. Té dret a
      exercir els drets d'accés a les seves dades personals, a la seva
      rectificació o supressió, a la limitació del seu tractament, a oposar-se
      al tractament proposat, i a consentir expressament la possibilitat de fer
      portabilitat de la seva informació. Per exercir els drets i per a
      qualsevol aclariment, pot dirigir-se per escrit a l'adreça postal que
      consta en aquest document, a l'adreça electrònica correu@teatrecpsv.com.
      L'organisme competent per conèixer dels conflictes derivats de la
      signatura d'aquest document és l'Autoritat Espanyola de Protecció de Dades
      ubicada a Madrid (28001), c. Jorge Juan, 6. <br />
      <br />
      5.2. - CONSENTIMENT DE L'USUARI <br />
      S'entendrà que l'usuari accepta les condicions establertes si prem el botó
      'ACCEPTAR' que es troba a tots els formularis de recollida de dades, o
      envia un correu electrònic a les adreces de contacte que figuren al web.
      <br />
      <br />
      5.3. - SEGURETAT <br />
      Les dades personals recollides dels nostres usuaris són emmagatzemades a
      la base de dades que garanteix les mesures oportunes d'índole tècnica i
      estructural per a prevenir la integritat i seguretat de la informació
      personal aportada i disposa d'un document de seguretat que compleix amb el
      Reial Decret 1720/2007, de 21 de desembre, pel qual s'aprova el Reglament
      de desenvolupament de la Llei Orgànica 15/1999, de 13 de desembre, de
      protecció de dades de caràcter personal. (BOE núm. 17 de 19/1/2008) i ha
      establert tots els mitjans tècnics al seu abast per evitar la pèrdua, mal
      ús, alteració, accés no autoritzat i robatori de les dades que vostè ens
      faciliti, sense perjudici d'informar-li que les mesures de seguretat a
      Internet no són inexpugnables. El tractament de les dades personals està
      ajustat a la normativa establerta en la Llei Orgànica 15/1999, 13 de
      desembre, de Protecció de Dades de Caràcter Personal (BOE 14/12/1999).
      <br />
      <br />
      5.4. -ÚS D'ADRECES IP I RECOLLIDA DE DADES ESTADÍSTIQUES <br />
      El Centre Parroquial Sant Vicenç l'informa que la navegació d'un usuari
      per aquest web deixa com a rastre l'adreça IP que li ha estat assignada
      pel seu proveïdor d'accés a Internet. Només es fa servir aquesta adreça
      per a fins exclusivament interns, com són les estadístiques d'accés al
      portal. <br />
      <br />
      5.5. - ENLLAÇOS <br />
      Per facilitar la recerca de recursos que creiem que són del seu interès,
      pot trobar en aquesta web enllaços a altres pàgines. Aquesta política de
      privacitat només és d'aplicació a aquest web. El Centre Parroquial Sant
      Vicenç no garanteix el compliment d'aquesta política en altres webs, ni es
      fa responsable dels accessos mitjançant enllaços des d'aquest lloc.
      <h4 className="mt-3">
        6. - MODIFICACIONS DE LES PRESENTS CONDICIONS I VIGÈNCIA{" "}
      </h4>
      El Centre Parroquial Sant Vicenç pot modificar les presents condicions en
      qualsevol moment, sense previ avís, publicant les seves modificacions tal
      com aquí apareixen. Un cop modificades, les presents condicions deixaran
      de ser vigents i seran substituïdes per la seva modificació.
      <h4 className="mt-3">7. - LEGISLACIÓ APLICABLE I JURISDICCIÓ </h4>La
      relació entre el Centre Parroquial Sant Vicenç i l'usuari es regirà per la
      normativa espanyola vigent i qualsevol controvèrsia se sotmetrà als
      Jutjats i tribunals de la ciutat de Sabadell.
    </div>
  );
}

export default SVTermsCOnditions;
