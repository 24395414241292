import "./App.css";
import React, { useState, useEffect } from "react";
import SVHeader from "./components/SVHeader";
import SVCarrouselPage from "./pages/SVCarrouselPage";
import { Route, Routes } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import SVFooter from "./components/SVFooter";
import SVPerfil from "./components/SVPerfil";
import SVConfig from "./components/Metadata/SVConfig";
import "./firebase/config";
import SVShowsPage from "./pages/SVShowsPage";
import SVTallerPage from "./pages/SVTallerPage";
import SVLoginPage from "./pages/SVLoginPage";
import SVShowPage from "./pages/SVShowPage";
import SVVideoPage from "./pages/SVVideoPage";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";
import { FilterProvider } from "./components/Contexts/FilterProvider";
import SVUsersPage from "./pages/SVUsersPage";
import SVTermsCOnditions from "./pages/SVTermsConditions";

function App() {
  const [usuario, setUsuario] = useState(null);
  const [cargandoUsuario, setCargandoUsuario] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((usuarioFirebase) => {
      if (usuarioFirebase) {
        const db = getFirestore();
        getDoc(doc(db, "Users", usuarioFirebase.uid)).then((userData) => {
          setUsuario(userData.data());
          setCargandoUsuario(false);
        });
      } else {
        setCargandoUsuario(false);
      }
    });
  }, []);

  return (
    <FilterProvider>
      <div className="App">
        <SVHeader usuario={usuario} />
        {!cargandoUsuario &&
          (usuario ? (
            <Container className="page-container">
              <Routes>
                <Route
                  path="/"
                  element={<SVCarrouselPage usuario={usuario} />}
                />
                <Route path="/config" element={<SVConfig />} />
                <Route
                  path="/users"
                  element={<SVUsersPage usuario={usuario} />}
                />
                <Route
                  path="/perfil"
                  element={
                    <SVPerfil usuario={usuario} setUsuario={setUsuario} />
                  }
                />
                <Route
                  path="/obres"
                  element={<SVShowsPage usuario={usuario} />}
                />
                <Route
                  path="/tallerDeTeatre"
                  element={<SVTallerPage usuario={usuario} />}
                />
                <Route
                  path="/obre/:id"
                  element={<SVShowPage usuario={usuario} />}
                />
                <Route
                  path="/obre/:id/video/:linkId"
                  element={<SVVideoPage usuario={usuario} />}
                />
                <Route path="/terms" element={<SVTermsCOnditions />} />
              </Routes>
              <Row className="custom-row">
                <SVFooter />
              </Row>
            </Container>
          ) : (
            <Routes>
              <Route
                path="/"
                element={<SVLoginPage setUsuario={setUsuario} />}
              />
              <Route path="/terms" element={<SVTermsCOnditions />} />
            </Routes>
          ))}
      </div>
    </FilterProvider>
  );
}

export default App;
