import "./Styles/SVComponents.css";
import { FaPlayCircle } from "react-icons/fa";

function SVButtonPlay(props) {
  return (
    <div
      className="boton-reproduccion p-5"
      onClick={() => props.selectVideo(props.idVideo)}
    >
      <FaPlayCircle className="icono-play" />

      <span className="leyenda">{props.descriptionLink}</span>
    </div>
  );
}

export default SVButtonPlay;
