import { React } from "react";
import SVShowMovil from "./SVShowMovil";
import SVShowPC from "./SVShowPC";

function SVShow(props) {
  return (
    <>
      {props.show && (
        <>
          <div className="d-md-none">
            <SVShowMovil show={props.show} />
          </div>
          <div className="d-none d-md-block">
            <SVShowPC show={props.show} />
          </div>
        </>
      )}
    </>
  );
}

export default SVShow;
