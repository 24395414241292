import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore/lite";

const SVSignupForm = ({ toggleLogin, setUsuario }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSignupSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const userCreated = userCredential.user;
        const newUser = {
          id: userCreated.uid,
          firstName: firstName,
          lastName: lastName,
          email: email,
          isSubscriptionActive: false,
          isAdmin: false,
        };
        const db = getFirestore();
        setDoc(doc(db, "Users", userCreated.uid), newUser).then(() => {
          setUsuario(newUser);
        });
      })
      .catch((error) => {
        alert(error.code);
      });
  };

  return (
    <Form onSubmit={handleSignupSubmit}>
      <Form.Group controlId="formBasicFirstName">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          placeholder="Introduïu el vostre nom"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mt-2" controlId="formBasicLastName">
        <Form.Label>Cognom</Form.Label>
        <Form.Control
          type="text"
          placeholder="Introduïu el vostre cognom"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mt-2" controlId="formBasicEmail">
        <Form.Label>Correu electrònic</Form.Label>
        <Form.Control
          type="email"
          placeholder="Introduïu el vostra correu electrònic"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mt-2" controlId="formBasicPassword">
        <Form.Label>Contrasenya</Form.Label>
        <Form.Control
          type="password"
          placeholder="Introduïu la vostra contrasenya"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mt-2" controlId="formBasicConfirmPassword">
        <Form.Label>Confirmar contrasenya</Form.Label>
        <Form.Control
          type="password"
          placeholder="Confirma la vostra contrasenya"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Group>
      <Button
        className="mt-3 boton-redCorporative"
        variant="primary"
        type="submit"
      >
        Registrar-se
      </Button>
      <br />
      <Button variant="link" onClick={toggleLogin}>
        Ja tens compte? Accedeix aquí.
      </Button>
    </Form>
  );
};

export default SVSignupForm;
