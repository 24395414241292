import React from "react";
import Card from "react-bootstrap/Card";
import "./Styles/SVComponents.css";
import { ShowTypes } from "./Metadata/Constants.js";
import { useNavigate } from "react-router-dom";

function SVCard(props) {
  const navigate = useNavigate();

  const handleShowClick = (id) => {
    navigate("/obre/" + id);
  };

  return (
    <div className="mb-3">
      <Card
        className="h-100 shadow"
        onClick={(e) => {
          handleShowClick(props.show.id);
        }}
      >
        <div className="overflow-hidden">
          {
            <Card.Img
              variant="top"
              src={props.show.refPosterImage}
              className="efectoZoom"
            />
          }
        </div>

        <Card.Body>
          <Card.Title>{props.show.title}</Card.Title>
          <Card.Text>{props.show.shortDescription} </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">{ShowTypes.get(props.show.type)}</small>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default SVCard;
