import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function SVFooter() {
  return (
    <div>
      <Row>
        <Col className="d-flex flex-row justify-content-center alig-items-center pt-3">
          <a
            href="https://www.facebook.com/TeatresantvicencSabadell"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-facebook socialMedia" />
          </a>
          <a
            href="https://twitter.com/teatresvicenc?lang=ca"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-twitter socialMedia"></i>
          </a>
          <a
            href="https://www.youtube.com/user/TeatreSantVicenc"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-youtube socialMedia"></i>
          </a>
          <a
            href="https://www.instagram.com/teatresvicenc/"
            target="blank"
            rel="noreferrer"
          >
            <i className="bi bi-instagram socialMedia"></i>
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="pt-1 text-center text-informative">
          {"Teatre Sant Vicenç. Montllor i Pujal 103. 08208 Sabadell."}
          <br />
          {"Telèfon: 93 717 94 01"} <br />
          <div className="fst-italic text-informative">
            {
              "Tots els continguts audiovisuals disponibles en aquesta aplicació estan protegits per drets d'autor i altres lleis de propietat intel·lectual. La reproducció, distribució o comunicació pública no autoritzada d'aquest contingut pot constituir una infracció dels drets de propietat intel·lectual. L'ús d'aquesta aplicació implica la teva acceptació dels termes i condicions dels drets d'autor aplicables a aquest contingut."
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SVFooter;
