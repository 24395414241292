import { React } from "react";

function SVLinkObra(props) {
  const handleViewClick = (id) => {
    window.open(`/obre/${id}`, "_blank");
  };

  return (
    <li key={props.dataShow.id} className="">
      <button type="button" class="btn btn-link" onClick={props.showSelect}>
        {props.dataShow.title} - {props.dataShow.directors}
      </button>
      {props.dataShow.isActivated ? (
        <i className="bi bi-check-circle-fill iconIsActivated"></i>
      ) : (
        <i className="bi bi-exclamation-circle-fill iconIsNotActivated"></i>
      )}
      <button
        type="button"
        className="btn"
        onClick={() => handleViewClick(props.dataShow.id)}
      >
        <i className="bi bi-eye-fill"></i>
      </button>
    </li>
  );
}

export default SVLinkObra;
