export function calculateSeason() {
  const hoy = new Date();
  const anyo = hoy.getFullYear();
  const mes = hoy.getMonth();

  // La temporada empieza en septiembre
  if (mes >= 8) {
    return `${anyo}-${anyo + 1}`;
  } else {
    return `${anyo - 1}-${anyo}`;
  }
}
