import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SVButtonDownload from "./SVButtonDownload";

function SVVideo(props) {
  return (
    <>
      {props.show && (
        <Container className="mt-2">
          <Row className="mt-2 align-items-center">
            <Col>
              <div className="text-center">
                <h2 className="SvTitulo">{props.show.title}</h2>
              </div>
            </Col>
            {props.show["urlDownload" + props.linkId] && (
              <Col className="d-none d-md-block">
                <SVButtonDownload
                  url={props.show["urlDownload" + props.linkId]}
                  name={props.show.title}
                />
              </Col>
            )}
          </Row>
          <hr />
          <Row className="align-items-center justify-content-center">
            <div className="iframe-container-vimeo d-flex justify-content-center align-items-center">
              <iframe
                src={props.show["urlLink" + props.linkId]}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="iframe-vimeo rounded-iframe "
                title={props.show.title}
              ></iframe>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}

export default SVVideo;
