import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

function SVInfoSub() {
  return (
    <Container>
      <div className="pt-5 pb-5">
        <h5>
          Accedeix al teu{" "}
          <Link to="/perfil" style={{ textDecoration: "none" }}>
            perfil
          </Link>{" "}
          per activar la subscripció.
        </h5>
      </div>
    </Container>
  );
}

export default SVInfoSub;
